import styled from "styled-components";
import { 
  Rem, 
  Colors, 
  BP,
} from "../../commons/Theme";

const impactBoxColor = "#B9CEDE70";

export const StyledTechCarousel2Container = styled.div`
`;

export const StyledTechCarousel2Card = styled.div`
  padding: ${Rem(30)};
  margin: 0 ${Rem(20)};
  background-color: ${Colors.yellowClear};
  border-radius: 20px;
  box-shadow: 0px 0px 50px rgba(51, 51, 51, 0.05);
`;

export const StyledTechCarousel2Item = styled.div`
  overflow: hidden;
  position: relative;
`;

export const StyledText = styled.div`
  margin: ${Rem(20)} 0;

  @media (${BP.tablet}) {
    margin: ${Rem(30)} 0;
  }
`;
export const StyledInfoContainer = styled.div`
  margin-top: 1rem;

  @media (${BP.tablet}) {
    margin-top: 0;
  }
`;

export const StyledCard = styled.div`
  top: 20px;
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  justify-content: start;
  gap: 0 ${Rem(30)};
  font-style: normal;
  font-weight: 300;
  font-size: ${Rem(14)};
  line-height: 150%;
  height: 100%;
  width: 100%;

  @media (${BP.tablet}) {
    flex-direction: row;
    width: 100%;
    gap: 0 ${Rem(30)};
    font-size: ${Rem(20)};
    right: ${(props) => props.position === "right" ? "0px" : "auto"};
  }
`;

export const StyledCardTitle = styled.div`
  font-weight: 700;
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  display: flex;
  align-items: center;
  
  @media (${BP.tablet}) {
    font-size: ${Rem(30)};
    line-height: ${Rem(36)};
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSubTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-family: Inter;
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
  margin-top: ${Rem(20)};

  @media (${BP.tablet}) {
    margin-top: ${Rem(40)};
    font-size: ${Rem(20)};
    line-height: ${Rem(26)};
  }
`;

export const StyledMediaContainer= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (${BP.tablet}) {
    flex-direction: column;
  }
`;

export const StyledMediaWrapper= styled.div`
  display: flex;
  flex: flex;
  background-color: ${impactBoxColor};
  border-radius: 20px;
  overflow: hidden;
  min-height: 240px;
  min-width: 240px;

  @media (${BP.tablet}) {
    min-width: 400px;
    min-height: 400px;
  }
`;

export const StyledHiveMediaContainer= styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  flex: 1;

  @media (${BP.tablet}) {
    align-items: end;
    margin-right: 20px;
  }
`;

export const StyledHiveMedia= styled.div`
  position: relative;
`;

export const StyledHiveInfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  margin-top: 1rem;

  @media (${BP.tablet}) {
    margin-top: 0;
  }
`;

export const StyledInfoBoxGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;

  @media (${BP.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledInfoBoxCard = styled.div`
  background-color: ${impactBoxColor};
  min-width: 180px;
  border-radius: 25px;
  padding: 27px 12px;
  display: flex;
  align-items: center;
`;

export const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 0.25rem;
`;

export const StyledInfoBoxValueContainer = styled.div`
  display: flex;
`;

export const StyledInfoBoxValue = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 14px;
`;

export const StyledInfoBoxMeasure = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const StyledInfoBoxLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const StyledCtaContainer = styled.div`
  margin: 1rem;
  text-align: center;
`;

export const StyledIconContainer = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
  background-color: white;
  border-radius: 100%;    
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIconTrack = styled.div`
  position: absolute;
  border-radius: 100%;
  border: 2px solid #FFF6E2;
  width: 35px;
  height: 35px;
`;

export const StyledIconImg = styled.img`
  position: absolute;
  width: 100%;
  margin-left: 1px;
  padding: 0.4rem;
`;

export const StyledSwiperBullets = styled.div`
  text-align: center;
  padding-top: ${Rem(5)};

  @media (${BP.tablet}) {
    padding-top: ${Rem(10)}
  }

  .swiper-pagination-bullet {
    width: ${Rem(10)};
    height: ${Rem(10)};
    margin: 0 ${Rem(8)} !important;
    border-radius: ${Rem(10)};
    background-color: ${Colors.grayDark};

    @media (${BP.tablet}) {
      width: ${Rem(16)};
      height: ${Rem(16)};
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.yellow};
  }
`;

export const StyledTooltipButton = styled.button`
  background-color: #E0E9F1;
  backdrop-filter: blur(20px);
  padding: 0.5rem;
  border: 3px solid rgba(185, 206, 222, 0.4);
  border-radius: 100%;
  z-index: -10;
`;

export const StyledTooltipContainer = styled.div`
  position: absolute;
  ${({ top }) => top ? `top: ${top};` : null}
  ${({ bottom }) => bottom ? `bottom: ${bottom};` : null}
  ${({ left }) => left ? `left: ${left};` : null}
  ${({ right }) => right ? `right: ${right};` : null}
  z-index: 10;
`;

export const StyledTooltip = styled.div`
  display: ${({ hidden }) => hidden ? "none" : "block"};
  
  position: absolute;
  z-index: 20;
  width: 200px;
  padding: 15px;
  background: rgba(185, 206, 222, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 15px;
  text-align: left;
  line-height: 18px;
  letter-spacing: 0em;
  font-size: 14px;
  left: -60px;

  @media (${BP.tablet}) {
    left: -205px;
    bottom: -50px;
    font-size: 11px;
  }

  span {
    font-weight: 700;
  }

  p {
    font-weight: 400;
  }
`;

export const StyledAudioIcon = styled.div`
  width: 24px;
  height: 24px; 
  display: ${({ hidden }) => hidden ? "none" : "block"};
  margin-bottom: 2px;
`;

export const StyledAudioButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: rgba(185, 206, 222, 0.4);
  backdrop-filter: blur(20px);
  padding: 0.5rem;
  border-radius: 15px;
  z-index: 10;
`;
